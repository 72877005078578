import { tr } from 'react-hook-form-auto'

import { Button } from 'ui/bootstrap/bootstrap'

export const renderModalButtons = ({
  invalid,
  submitForm,
  onClose
}) =>
  <div>
    <Button
      text={tr('modal.save')}
      type="primary"
      iconClass="check"
      disabled={invalid}
      onClick={submitForm}
    />
    <Button
      text={tr('modal.close')}
      iconClass="trash"
      onClick={onClose}
    />
  </div>

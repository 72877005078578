import { createSchema } from 'react-hook-form-auto'
import gql from 'graphql-tag'

import { fileFragment } from 'pintor'
import { useMutation } from '../graphql/notificatingMutation'

export const NutrimentSchema = createSchema('nutriment', {
  type: {
    type: 'select',
    options: [
      'sugars',
      'salt',
      'proteins',
      'saturatedFat',
      'sodium',
      'energy',
      'fat',
      'carbohidrates'
    ]
  },
  amount: {
    type: 'number'
  },
  unit: {
    type: 'select',
    options: [
      'kcal',
      'kJ',
      'kg',
      'g',
      'ml',
      'l',
      'percentVol'
    ]
  }
})

export const IngredientSchema = createSchema('ingredient', {
  name: {
    type: 'string',
    required: true,
    max: 255
  },
  groupId: {
    type: 'select',
    coerce: value => value === '' ? null : parseInt(value),
    options: props => {
      const groups = props && props.groups || []

      return groups.map(group => ({
        value: group.id,
        label: group.name
      }))
    },
    associatedData: 'groups',
    labelField: 'name'
  },
  nutriments: {
    type: [NutrimentSchema],
    initiallyEmpty: true
  },
  imageIds: {
    type: 'images',
    onlyOne: true
  }
})

export const ingredientFragment = gql`
  fragment IngredientFragment on Ingredient {
    id
    createdAt
    name
    nutriments {
      type
      amount
      unit
    }
    images {
      ...FileFragment
    }
    group {
      id
      name
    }
  }

  ${fileFragment}
`

const INGREDIENTS_GET = gql`
  query IngredientsList(
    $search: String,
    $first: Int,
    $num: Int,
    $seeEveryones: Boolean
  ) {
    collection: ingredients(
      search: $search,
      first: $first,
      num: $num,
      seeEveryones: $seeEveryones
    ) {
      ...IngredientFragment
    }
  }
  ${ingredientFragment}
`

const INGREDIENTS_SUB = gql`
  subscription IngredientUpdate {
    ingredientChange {
      ...IngredientFragment
    }
  }
  ${ingredientFragment}
`

const INGREDIENTS_COUNT = gql`
  query IngredientCount($search: String, $seeEveryones: Boolean) {
    count: ingredientsCount(search: $search, seeEveryones: $seeEveryones)
  }
`

const INGREDIENT_GET = gql`
  query IngredientGet($id: ID!) {
    ingredient(id: $id) {
      ...IngredientFragment
    }
  }
  ${ingredientFragment}
` 

const INGREDIENT_UPDATE = gql`
  mutation IngredientUpdate($input: IngredientInput!) {
    ingredientUpdate(input: $input) {
      ...IngredientFragment
    }
  }
  ${ingredientFragment}
`

function formConvert(doc) {
  const images = doc.images || []

  return {
    ...doc,
    imageIds: images.map(image => image.id),
    groupId: doc.group && doc.group.id
  }
}

function mutationConvert(doc) {
  let {
    __typename,
    images,
    imageIds,
    nutriments,
    group,
    groupId,
    createdAt,
    ...rest
  } = doc

  const useIds = imageIds || images.map(image => image.id)

  return {
    variables: {
      input: {
        ...rest,
        imageIds: useIds,
        groupId: groupId || (group && group.id),
        nutriments: nutriments.map(({ type, amount, unit }) => ({
          type,
          amount,
          unit
        }))
      }
    }
  }
}

function useUpdate(options = {}) {
  const [ mutate ] = useMutation(INGREDIENT_UPDATE, options)

  return async doc => {
    const converted = mutationConvert(doc)
    const result = await mutate(converted)

    return result && result.data && result.data.ingredientUpdate
  }
}

export const Ingredient = {
  name: 'ingredient',
  schema: IngredientSchema,
  search: INGREDIENTS_GET,
  fetch: INGREDIENTS_GET,
  sub: INGREDIENTS_SUB,
  mainSubName: 'ingredientChange',
  mainSubCollection: 'collection',
  count: INGREDIENTS_COUNT,
  get: INGREDIENT_GET,
  update: INGREDIENT_UPDATE,
  useUpdate,
  fragment: ingredientFragment,
  formConvert,
  mutationConvert
}

import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Input } from '../../ui/bootstrap/bootstrap'

const KEY_UP = 38
const KEY_DOWN = 40
const KEY_ENTER = 13

// Nota: Los props extra se los pasa a los resultados resultsComponent
export let InputSearch = ({
  onSelect,
  name,
  model,
  reset,
  setValue,
  className,
  placeholder,
  defaultValue,
  createComponent,
  cleanAfterSelect,
  onPageChange,
  onCreate,
  children,
  onChange,
  onBlur,
  inputRef,
  ...rest
}) => {
  const initialSearch = defaultValue && defaultValue.search || ''

  const [ creating, setCreating ] = useState(false)
  const [ search, setSearch ] = useState(initialSearch)
  const [ searching, setSearching ] = useState(false)

  const results = useRef([])
  const [ selectedIdx, setSelectedIdx ] = useState(0)

  const handleCreateOpen = () => {
    setCreating(true)
  }

  const handleCreateClose = () => {
    setCreating(false)
  }

  const searchBegin = (e) => {
    const searchFor = e.target.value

    setSearch(searchFor)
    setSearching(true)

    if (onPageChange)
      onPageChange(0)
  }

  const searchEnd = () => {
    setSearching(false)
  }

  const handleResults = (newResults) => {
    results.current = newResults
  }

  const handleSelect = (doc) => {
    if (onSelect) {
      if (cleanAfterSelect)
        setSearch('')
      else
        setSearch(doc.name)
      searchEnd()
      onSelect(doc, name)
    }
  }

  const handleKeyDown = (e) => {
    const codez = e.keyCode
    if (codez == KEY_UP || codez == KEY_DOWN) {
      e.preventDefault()

      const num = results.current ? results.current.length : 0
      const idx = selectedIdx || 0
      let desiredIdx
      if (codez == KEY_UP)
        desiredIdx = idx - 1
      if (codez == KEY_DOWN)
        desiredIdx = idx + 1
      if (desiredIdx < 0)
        desiredIdx = 0
      if (desiredIdx > num)
        desiredIdx = 0

      setSelectedIdx(desiredIdx)
    }

    if (codez == KEY_ENTER) {
      e.preventDefault()

      const entry = results.current[selectedIdx]
      handleSelect(entry)
    }
  }

  const focus = () => {
    debugger
    this.refs.search.focus()
  }

  const renderResults = ({
    form,
    search,
    meta,
    searching,
    resultsComponent,
    alwaysResults,
    ...rest
  }) => {
    if (searching || alwaysResults) {
      const $resultsComponent = resultsComponent

      return (
        <$resultsComponent
          search={search}
          searching={searching}
          onSelect={handleSelect}
          onAddSelected={handleCreateOpen}
          onResults={handleResults}
          onPageChange={onPageChange}
          selectedIdx={selectedIdx}
          {...rest}
        />
      )
    } else
      return null
  }

  const createProxy = async (doc) => {
    handleCreateClose()
    searchEnd()

    return await onCreate(doc, name)
  }
  const $createComponent = createComponent

  const searchDoc = {
    name: search
  }

  return (
    <div className="search">
      <$createComponent
        visible={creating}
        onSubmit={createProxy}
        model={model}
        name={name}
        onClose={handleCreateClose}
        initialValues={searchDoc}
      />
      <Input
        name={`${name}.search`}
        className={className}
        placeholder={placeholder}
        defaultValue={initialSearch}
        onChange={searchBegin}
        onKeyDown={handleKeyDown}
        inputRef={inputRef}
        autoFocus
        autoComplete="false"
      />
      {children}
      {renderResults({ searching, search, model, ...rest })}
    </div>
  )
}

import * as React from 'react';
import { PropTypes } from 'prop-types'
import classnames from 'classnames'

const ADD_KEY = "__add__"

export const arrayAddAdd = (Component, Item) => {
  class Adder extends React.Component {
    constructor(props) {
      super(props)
    }

    renderAdd() {
      if (this.props.addAdd) {
        return (
          <Item
            key={ADD_KEY}
            index={ADD_KEY}
            onClick={this.props.onAdd}
          >
            {this.props.addComponent}
          </Item>
        )
      } else
        return null
    }

    renderList() {
      const children = this.props.children || []
      const add = this.renderAdd()

      if (add) {
        if (this.props.addIsFirst)
          return [add, ...children]
        else
          return [...children, add]
      } else {
        return children
      }
    }

    render() {
      const { addAdd, addComponent, onAdd,
              addIsFirst, ...rest } = this.props
      return (
        <Component {...rest}>
          {this.renderList()}
        </Component>
      )
    }
  }

  return Adder
}

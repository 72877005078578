import { objectMap } from 'pintor'

export const menusTable = {
  INGREDIENTS: {
    textId: 'models.ingredients',
    path: '/ingredients',
    icon: 'tag',
  },
  MEALS: {
    textId: 'models.meals',
    path: '/meals',
    icon: 'utensils',
  },
  SHOP: {
    textId: 'models.shop',
    path: '/shop',
    icon: 'shopping-cart',
  },
  ORDERS: {
    textId: 'models.bought',
    path: '/orders',
    icon: 'calendar-check',
  },
  ORGANIZE: {
    textId: 'models.organize',
    icon: 'sitemap',
    submenu: {
      GROUPS: {
        textId: 'models.groups',
        path: '/groups',
        icon: 'list-alt',
      },
      DIETS: {
        textId: 'models.diets',
        path: '/diets',
        icon: 'calendar',
      },
      SHOP_HISTORY: {
        textId: 'models.history',
        path: '/history',
        icon: 'history'
      }
    }
  }
}

export const mapRoutes = (cb, table = menusTable) => {
  return objectMap(table, cb)
}

export const matchRoute = (location, def) => location || def

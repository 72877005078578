import styles from 'autoform/Autoform.sass'
import classnames from 'classnames'

export let Input = ({
  elementOnly,
  className,
  inputComponent = 'input',
  inputRef,
  ...rest
}) => {
  const $input = inputComponent
  const classes = classnames(className, styles.input)

  return (
    <$input {...rest} ref={inputRef} className={classes} />
  )
}

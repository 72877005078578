import { createSchema, tr } from 'react-hook-form-auto'
import gql from 'graphql-tag'
import { useQuery, useSubscription } from '@apollo/client'

import { createUpdater } from 'pintor'

export const IngredientGroupSchema = createSchema('ingredientGroup', {
  name: {
    type: 'string',
    required: true,
    max: 255
  }
})

const ingredientGroupFragment = gql`
  fragment IngredientGroupFragment on IngredientGroup {
    id
    createdAt
    name
  }
`

const INGREDIENT_GROUPS_GET = gql`
  query IngredientGroupsList {
    collection: ingredientGroups {
      ...IngredientGroupFragment
    }
  }
  ${ingredientGroupFragment}
`

const INGREDIENT_GROUP_INGREDIENTS_GET = gql`
  query IngredientGroupIngredients($groupId: String!, $long: Boolean)  {
    collection: ingredientGroupIngredients(groupId: $groupId, long: $long) {
      id
      name
    }
  }
`

const INGREDIENT_GROUPS_SUB = gql`
  subscription IngredientGroupUpdate {
    ingredientGroupChange {
      ...IngredientGroupFragment
    }
  }
  ${ingredientGroupFragment}
`

const INGREDIENT_GROUPS_COUNT = gql`
  query IngredientGroupCount($search: String) {
    count: ingredientGroupsCount(search: $search)
  }
`

const INGREDIENT_GROUP_GET = gql`
  query IngredientGroupGet($id: ID!) {
    ingredientGroup(id: $id) {
      ...IngredientGroupFragment
    }
  }
  ${ingredientGroupFragment}
`

const INGREDIENT_GROUP_UPDATE = gql`
  mutation IngredientGroupUpdate($input: IngredientGroupInput!) {
    ingredientGroupUpdate(input: $input) {
      ...IngredientGroupFragment
    }
  }
  ${ingredientGroupFragment}
`

// FIXME Rudimentary
function hasAmounts(doc) {
  const { amounts } = doc

  return amounts && amounts.length > 0
}

// Extracts group information from buys
function processGroups(buys) {
  return buys && buys.reduce((groups, buy) => {
    const { ingredient: { group } } = buy

    if (hasAmounts(buy)) {
      const name = group ?
        group.name : tr('models.ingredient_groups.ungrouped')

      if (!groups[name])
        groups[name] = []
      groups[name].push(buy)
    }

    return groups
  }, {})
}

const dataUpdater = createUpdater({
  query: INGREDIENT_GROUPS_GET,
  subscriptionName: 'ingredientGroupChange',
  collectionKey: 'collection'
})

export const useIngredientGroupUpdate = () => {
  const {
    data,
    loading
  } = useSubscription(INGREDIENT_GROUPS_SUB, {
    onSubscriptionData: dataUpdater
  })
  const ingredientGroupChange = data && data.ingredientGroupChange

  return [ ingredientGroupChange, loading ]
}

export const useGroups = () => {
  const { data, loading } = useQuery(INGREDIENT_GROUPS_GET)
  const collection = data && data.collection

  return [ collection, loading ]
}

export const IngredientGroup = {
  name: 'ingredientGroup',
  schema: IngredientGroupSchema,
  fetch: INGREDIENT_GROUPS_GET,
  sub: INGREDIENT_GROUPS_SUB,
  count: INGREDIENT_GROUPS_COUNT,
  get: INGREDIENT_GROUP_GET,
  update: INGREDIENT_GROUP_UPDATE,
  fragment: ingredientGroupFragment,
  filter: hasAmounts,
  processGroups,
  hasAmounts,
  useIngredientGroupUpdate,
  fetchIngredients: INGREDIENT_GROUP_INGREDIENTS_GET
}

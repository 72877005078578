const name = 'shop'
const tokenName = new Buffer.from(name + '.token-for-encrypt', 'base64')
const client = 'shop/src'

const internalPort = '8908'

const configs = {
  common: {
    authTokenName: tokenName,
    cookieKey: name + '_session',
    name,
    applicationName: 'Shop t2',
    graphqlPath: '/graphql',
    clientPaths: {
      build: 'shop/renders/client',
      base: 'shop',
      client
    },
    serverPaths: {
      build: 'shop/renders/server',
      server: 'shop/server',
      base: 'shop',
      client
    },
    defaultServings: 2,
    business: false,
    crud: {
      normal: {
        perPage: 20,
        side: 170
      },
      small: {
        perPage: 32,
        side: 120
      },
      list: { perPage: 40 }
    }
  },
  local: {
    jwtSecret: '5F37A92E79D88943D11D49DD7B62DB5520E40AD7B9EB1F7904A012FFDEB3515F',

    port: internalPort,
    portExternal: internalPort,
    host: 'localhost',
    hostExternal: 'astro-eth.lan',
    externalProto: 'http',
    wsExternalProto: 'ws',
    wsExternalPort: `:${internalPort}`,

    knex: {
      client: "postgresql",
      debug: true,
      connection: {
        host: 'db',
        database: 'shop',
        user: 'docker',
        password: 'docker'
      },
      pool: {
        min: 2,
        max: 10
      },
      migrations: {
        tableName: "knex_migrations"
      }
    }
  },
  espejismo: {
    jwtSecret: '960CD2B4E2A96E6117D8C71047CF4E77C3209CFD8A83BAE0E77EDB27ED25C677',

    port: internalPort,
    portExternal: null,
    host: 'localhost',
    hostExternal: 'shop0.espejismo.co',
    externalProto: 'https',
    wsExternalProto: 'wss',
    wsExternalPort: '',

    knex: {
      client: "postgresql",
      connection: {
        host: 'db',
        database: 'shop',
        user: 'docker',
        password: 'docker'
      },
      pool: {
        min: 2,
        max: 10
      },
      migrations: {
        tableName: "knex_migrations"
      }
    }
  }
}

const getConfig = () => {
  const mode = process.env.MODE || 'local'
  const envConfig = configs[mode]
  const { host, hostExternal, port, portExternal } = envConfig
  const portAdd = port ? `:${port}` : ''
  const extPortAdd = portExternal ? `:${portExternal}` : ''
  const serverPath = `${envConfig.host}${portAdd}`
  const serverUri = `http://${serverPath}`
  const serverExtUri = `${envConfig.externalProto}://${hostExternal}${extPortAdd}`
  const graphExtBase = `${envConfig.wsExternalProto}://${hostExternal}`

  const base = {
    ...configs.common,
    ...envConfig
  }

  return {
    ...base,
    mode: process.env.NODE_ENV,
    webpackConfigMode: process.env.WEBPACK_CONFIG_MODE,
    serverPath,
    serverUri,
    graphqlUri: `${serverUri}${base.graphqlPath}`,
    graphqlExtUri: `${serverExtUri}${base.graphqlPath}`,
    graphqlWsUri: `${graphExtBase}${extPortAdd}${base.graphqlPath}`,
  }
}

module.exports = {
  getConfig
}

import { Component } from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { DropdownMenu, Icon } from 'ui/bootstrap/bootstrap'
import { renderMatch, ButtonConfig } from 'pintor'

import styles from './IngredientTag.sass'

const icons = {
  ingredient: 'tag',
  add: 'plus'
}

export class IngredientTag extends Component {
  handleShowNutriments = (e) => {
    e.preventDefault()
  }

  renderNutrimentsOverlay(doc) {
    if (doc && doc.nutriments) {
      const buttoner = [
        new ButtonConfig(doc => ({
          type: 'default',
          icon: 'apple',
          onClick: this.handleShowNutriments
        }))
      ] 

      return (
        <DropdownMenu
          icon="info"
          buttons={buttoner}
        />
      )
    } else
      return null
  }

  handleIngredientClick = (e) => {
    if (!e.defaultPrevented && this.props.onClick)
      this.props.onClick(this.props.doc)
  }

  render() {
    const {
      doc,
      isAdd,
      onClick,
      text,
      children,
      selected,
      short,
      className,
      search
    } = this.props

    const type = doc ? 'ingredient' : 'add'
    const icon = icons[type]
    const displayText = text || doc.name
    const classes = classnames(className, {
      [styles.ingredient]: !isAdd,
      [styles.add]: isAdd,
      [styles.selected]: selected
    })

    return (
      <span className={classes} onClick={this.handleIngredientClick} >
        <span className={styles.name}>
          <Icon
            icon={icon}
            text={renderMatch(displayText, search, styles)}
            iconClass={styles.icon}
          />
        </span>
        {children}
        { /* this.renderNutrimentsOverlay(doc) */ }
      </span>
    )
  }
}


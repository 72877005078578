import { Input } from './Input.jsx'

const renderOptions = options =>
  options.map(op =>
    <option
      key={op.value}
      value={op.value}
    >
      {op.label}
    </option>
  )

export const Select = props => {
  const { options, ...rest } = props
  const optionsWithNull = [ ...options, {
    label: '',
    value: ''
  } ]

  return (
    <Input
      className="form-control"
      {...rest}
      inputComponent="select"
    >
      {renderOptions(optionsWithNull)}
    </Input>
  )
}

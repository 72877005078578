import { Component } from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { IngredientTag } from './IngredientTag'
import { FlexItem } from 'components/FlexList'

import styles from './IngredientTag.sass'

export class IngredientTagFlex extends Component {
  render() {
    const {
      doc,
      isAdd,
      onClick,
      selected,
      ...rest
    } = this.props

    const id = isAdd ? '__add__' : doc.id

    const classes = classnames(styles.pill, {
      [styles.ingredient]: !isAdd,
      [styles.add]: isAdd,
      [styles.selected]: selected
    })

    return (
      <FlexItem
        key={id}
        className={classes}
        onClick={onClick}
      >
        <IngredientTag
          key={id}
          doc={doc}
          isAdd={isAdd}
          selected={selected}
          {...rest}
        />
      </FlexItem>
    )
  }
}

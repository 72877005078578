import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icon from './Icon.jsx'

const renderContent = ({
  iconClass,
  text
}) => {
  if (iconClass) {
    return (
      <Icon
        icon={iconClass}
        text={text}
      />
    )
  } else
    return text
}

const buttonType = ({ submit }) => submit ? 'submit' : 'button'

const handleClick = ({
  disabled,
  onClick
}, e) => {
  if (!disabled && onClick)
    onClick(e)
}

const btnClass = (outline, type = 'secondary') => {
  const outlineStr = outline ? 'outline-' : ''
  return `btn-${outlineStr}${type}`
}

export const Button = ({
  text = '',
  onFocus,
  onClick,
  config,
  className,
  iconClass,
  type,
  submit,
  disabled,
  children,
  outline,
  pressed,
  small,
  ...rest
}) => {
  if (config) {
    text = config.text
    onClick = config.onClick
    onFocus = config.onFocus
    iconClass = config.icon
  }

  const buttonClass = btnClass(outline, type)
  const classes = classnames(
    className,
    'btn',
    buttonClass,
    {
      'btn-sm': small,
      active: pressed,
      disabled
    }
  )

  return (
    <button
      type={buttonType({ submit })}
      className={classes}
      onClick={handleClick.bind(null, { disabled, onClick })}
      onFocus={onFocus}
      aria-pressed={pressed}
      {...rest}
    >
      {renderContent({ iconClass, text })}
      {children}
    </button>
  )
}

export const buttonFromConfig = (button, key) =>
  <Button
    key={key}
    type={button.type}
    iconClass={button.icon}
    text={button.text}
    onClick={button.onClick}
  />

Button.propTypes = {
  text: PropTypes.string,
  button: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  iconClass: PropTypes.string,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  pressed: PropTypes.bool
}

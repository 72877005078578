import { Component } from 'react';
import classnames from 'classnames'

const renderHead = head =>
  head && <thead>{head}</thead>

export const Table = ({
  className,
  children,
  head
}) => {
  const classes = classnames(
    'table',
    'table-stripped',
    className
  )

  return (
    <table className={classes}>
      {renderHead(head)}
      <tbody>
        {children}
      </tbody>
    </table>
  )
}

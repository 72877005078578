import React, { Component } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  concat,
  split
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import apolloLogger from 'apollo-link-logger'
import { createUploadLink } from 'apollo-upload-client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
// import { SchemaLink } from '@apollo/client/link/schema'

const createAuthLink = token =>
  setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token || ''
    }
  }))

const getSSRLinks = ({ token, link }) => {
  const authLink = createAuthLink(token)

  return concat(authLink, link)
}

export const createClient = ({
  ssr,
  req,
  token,
  uri,
  cookie,
  cache,
  fetch,
  WS,
  wsUri,
  schema,  // for SSR
  addClientContext,
  defaultOptions,
  skipLogging
} = {}) => {
  let useLink

  if (ssr)
    console.log("APOLLO CLIENT SSR URL", uri)

  let httpOptions = {
    uri: uri,
    credentials: "same-origin",
    fetch,
  }
  if (ssr) {
    httpOptions.headers = {
      cookie: req.header('Cookie')
    }
  }

  if (ssr) {
    let link

    // SchemaLink says schema, produced with Apollo's makeExecutableSchema,
    // is not an executable schema.
    //
    // if (schema)
    //   link = new SchemaLink({ schema })
    // else
      link = createHttpLink(httpOptions)

    useLink = getSSRLinks({ token, link })
    if (addClientContext) {
      const extraContext = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          ...addClientContext(headers)
        }
      }))

      useLink = concat(useLink, extraContext)
    }
  } else {
    let connectionParams = { authToken: token }
    if (addClientContext) {
      connectionParams = {
        ...connectionParams,
        ...addClientContext()
      }
    }

    const wsClient = new SubscriptionClient(wsUri, {
      reconnect: true,
      connectionParams
    }, WS)

    const wsLink = new WebSocketLink(wsClient)
    const uploadLink = concat(
      createAuthLink(token),
      createUploadLink(httpOptions)
    )

    useLink = split(({ query }) => {
      const { name: { value } } = getMainDefinition(query)

      return value == 'Upload'
    }, uploadLink, wsLink)
  }

  let link = skipLogging ? [] : [ apolloLogger ]
  link.push(
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map((entry) => {
          const message = JSON.stringify(entry, null, '\t')

          console.error(
            `[GraphQL client error] ${message}`
          )
        })
      }
      if (networkError) {
        const structured = JSON.stringify(networkError)
        console.error(`[Network error in graphql client]: ${structured}`)
      }
    })
  )
  link.push(useLink)

  return new ApolloClient({
    ssrMode: ssr,
    link: ApolloLink.from(link),
    cache: cache || new InMemoryCache(),
    defaultOptions
  })
}

import { useState } from 'react';

import { Button } from './Button'

export const ToggleButton = ({
  value,
  onChange,
  ...rest
}) =>
  <Button
    pressed={value}
    onClick={() => onChange(!value)}
    {...rest}
  />

import { Helmet, HelmetProvider } from 'react-helmet-async'
import { Routes } from './Routes'
import { hot } from 'react-hot-loader/root'
import config from './config'
import { toast } from 'react-toastify'
import { setPintorHooks } from 'pintor'
import {
  useQuery,
  useSubscription,
  useApolloClient
} from '@apollo/client'
import { useMutation } from './graphql/notificatingMutation'

import { addTranslations } from 'react-hook-form-auto'
import es from 'translations/es.json'
import en from 'translations/en.json'

if (typeof navigator != 'undefined') {
  const userLang = navigator.language || navigator.userLanguage
  const shortLang = userLang.substr(0, 2)
  if (shortLang == 'es')
    addTranslations(es)
  else
    addTranslations(en)
} else {
  addTranslations(es)
}

toast.configure()

import 'bootstrap/dist/css/bootstrap.min.css'
import './ui/lato.css'
import './ui/rubik.css'

import 'App.sass'
import 'pintor/lib/pintor.css'

import 'react-toastify/dist/ReactToastify.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faTh,
  faThLarge,
  faList,
  faInfo,
  faCog,
  faTag,
  faTrash,
  faEdit,
  faPlus,
  faCheck,
  faUtensils,
  faFire,
  faClock,
  faShoppingCart,
  faBars,
  faClipboardList,
  faCheckCircle,
  faCalendarCheck,
  faCalendar,
  faEraser,
  faTimes,
  faFirstAid,
  faEllipsisH,
  faListAlt,
  faUserTie,
  faUserCircle,
  faUserEdit,
  faSitemap,
  faHistory,
  faSave,
  faChevronUp,
  faChevronDown,
  faComment,
  faQuoteRight,
  faSignOutAlt,
  faExclamationTriangle,
  faUsers
} from '@fortawesome/free-solid-svg-icons'

library.add(faTh)
library.add(faThLarge)
library.add(faList)
library.add(faInfo)
library.add(faCog)
library.add(faTag)
library.add(faTrash)
library.add(faEdit)
library.add(faPlus)
library.add(faCheck)
library.add(faUtensils)
library.add(faFire)
library.add(faClock)
library.add(faShoppingCart)
library.add(faBars)
library.add(faClipboardList)
library.add(faCheckCircle)
library.add(faCalendarCheck)
library.add(faCalendar)
library.add(faEraser)
library.add(faTimes)
library.add(faFirstAid)
library.add(faEllipsisH)
library.add(faListAlt)
library.add(faUserTie)
library.add(faUserCircle)
library.add(faUserEdit)
library.add(faSitemap)
library.add(faHistory)
library.add(faSave)
library.add(faChevronUp)
library.add(faChevronDown)
library.add(faComment)
library.add(faQuoteRight)
library.add(faSignOutAlt)
library.add(faExclamationTriangle)
library.add(faUsers)

setPintorHooks({
  useQuery,
  useMutation,
  useSubscription,
  useApolloClient
})

const App = ({ forcePath }) => {
  return (
    <HelmetProvider>
      <div className="container-fluid">
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>{config.applicationName}</title>
        </Helmet>
        <Routes forcePath={forcePath} />
      </div>
    </HelmetProvider>
  )
}

export default hot(App)

import { Container } from 'reactstrap'
import { tr } from 'react-hook-form-auto'
import classnames from 'classnames'

import config from '../config'

import styles from './Greetings.module.sass'

const Data = ({
  className,
  textId
}) =>
  <div className={styles.container}>
    <h2 className={styles.title}>{tr(`business.title.${textId}`)}</h2>
    <div className={classnames(styles.image, className)}></div>
    <p>{tr(`business.${textId}`)}</p>
  </div>

export const Greetings = () => 
  <Container fluid>
    <h1 className="display-3">{config.applicationName}</h1>
    <p className="lead">{tr('business.pitch')}</p>
    <div className={styles.pitch}>
      <Data key="meals" className={styles.meals} textId="meals" />
      <Data key="meal" className={styles.meal} textId="meal" />
      <Data key="shop" className={styles.shop} textId="shop" />
    </div>
  </Container>

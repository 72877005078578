import { useState, forwardRef } from 'react'
import { tr, createSchema } from 'react-hook-form-auto'
import { Autoform } from 'autoform/Autoform'
import { useForm } from 'react-hook-form'

import { ButtonGroup, Button as ShopButton } from 'reactstrap'
import { Button, Input } from '../../ui/bootstrap/bootstrap'

import { validate } from './LoginFormValidate'

import styles from './LoginForm.sass'

const loginSchemaConfig = {
  username: {
    type: 'string'
  },
  password: {
    type: 'password'
  },
}

const loginSchema = createSchema('login', loginSchemaConfig)

const registerSchema = createSchema('register', {
  ...loginSchemaConfig,
  password_repeat: {
    type: 'password'
  },
  email: {
    type: 'string'
  }
})

let WrappedInput = ({
  label,
  ...rest
}, ref) => {
  return (
    <>
      <label className={styles.label}>{label}</label>
      <Input {...rest} ref={ref} />
    </>
  )
}

WrappedInput = forwardRef(WrappedInput)

export const LoginForm = ({
  initiallyRegister,
  onSubmit,
  onClose
}) => {
  const [ canSubmit, setCanSubmit ] = useState(false)
  const [ isRegister, setIsRegister ] = useState(initiallyRegister)
  const { register, handleSubmit } = useForm()

  const enableButton = () => {
    setCanSubmit(true)
  }

  const disableButton = () => {
    setCanSubmit(false)
  }

  const submit = (doc) => {
    onSubmit({
      doc,
      isRegister
    })

    if (onClose)
      onClose()
  }

  // submitForm = () => {
  //   this.props.dispatch(submit(formName))
  // }

  // handleKeyDown = e => {
  //   if (e.charCode == 13 && !e.shiftKey)
  //     this.submitForm()
  // }

  const submitDisabled = () => {
    return !canSubmit
  }

  const renderPasswordConfirm = () => {
    if (isRegister) {
      return (
        <Input
          name="password_repeat"
          type="password"
          label={tr('auth.password-repeat')}
          {...register('password_repeat')}
        />
      )
    } else
      return false
  }

  const getActionText = () => {
    if (isRegister)
      var id = "register"
    else
      var id = "login"

    return tr(`auth.${id}`)
  }

  // render()
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className={styles.group}>
        <ButtonGroup>
          <Button
            disabled={!isRegister}
            onClick={setIsRegister.bind(null, false)}
          >
            {tr('auth.login')}
          </Button>
          <Button
            disabled={isRegister}
            onClick={setIsRegister.bind(null, true)}
          >
            {tr('auth.register')}
          </Button>
        </ButtonGroup>      
      </div>
      <Autoform
        schema={isRegister ? registerSchema : loginSchema}
        onSubmit={submit}
        submitButtonText={getActionText()}
        submitButton
      />
    </form>
  )
}

import classnames from 'classnames'

export const Navbar = ({
  children,
  inverse
}) => {
  const classes = classnames('navbar',
    'navbar-' + (inverse ? 'inverse' : 'default'))

  return (
    <nav className={classes}>
      {children}
    </nav>
  )
}

import { Jumbotron } from 'reactstrap'

import { Greetings } from '../Greetings'

import styles from './LoginScreen.sass'

import { Login } from './Login'

export const LoginScreen = () => {
  return (
    <Jumbotron fluid>
      <Greetings />
      <Login register={false} />
    </Jumbotron>
  )
}

import { useRoutes, usePath } from 'hookrouter'
import loadable from '@loadable/component'

import { MainMenu } from './components/MainMenu'
import { useUser } from 'pintor'

import { LoginScreen } from './views/login/LoginScreen'

// const Login = loadable(props => import('./views/login/LoginScreen'))
// const IngredientsView = loadable(props => import('./views/ingredients/IngredientsView'))
const IngredientsView = loadable(props => import('./GWIngredientsView'))
const MealsView = loadable(props => import('./GWMealsView'))
const ShopView = loadable(props => import('./GWShopView'))
const OrdersContainer = loadable(props => import('./GWOrdersContainer'))
const DietsContainer = loadable(props => import('./GWDietsContainer'))
const GroupsContainer = loadable(props => import('./GWGroupsContainer'))
const HistoryContainer = loadable(props => import('./GWHistoryContainer'))
const Greetings = loadable(props => import('./GWGreetings'))
const MealView = loadable(props => import('./GWMealView'))
const ProfileContainer = loadable(props => import('./GWProfileContainer'))

import { ViewTransition } from 'ui/transitions/ViewTransition'

import NotFound from './components/NotFound'

const routes = {
  '/': () => <ShopView />,
  '/login': () => <LoginScreen />,
  '/ingredients': () => <IngredientsView />,
  '/meal/:id': ({ id }) => <MealView id={id} />,
  '/meals': () => <MealsView />,
  '/shop': () => <ShopView />,
  '/orders': () => <OrdersContainer />,
  '/diets/:id': ({ id }) => <DietsContainer id={id} />,
  '/diets': () => <DietsContainer />,
  '/groups': () => <GroupsContainer />,
  '/history': () => <HistoryContainer />,
  '/profile': () => <ProfileContainer />
}

const publicRoutes = [ '/meal/' ]

const equivalences = {
  '/': '/shop'
}

export const Routes = ({ forcePath }) => {
  const routeResult = useRoutes(routes)
  const { currentUser } = useUser()

  const routerPath = usePath()
  const path = forcePath || routerPath

  const isPublic = publicRoutes.reduce((isPublic, cur) =>
    path.indexOf(cur) == 0 ? true : isPublic
  , false)

  if (isPublic) {
    return (
      <div>
        {routeResult || <NotFound />}
      </div>
    )
  } else if (currentUser) {
    return (
      <div>
        <MainMenu
          page={path}
          equivalences={equivalences}
        />
        <ViewTransition viewKey={path}>
          {routeResult || <NotFound />}
        </ViewTransition>
      </div>
    )
  } else
    return <LoginScreen />
}

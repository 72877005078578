import { Component } from 'react';

import { tr } from 'react-hook-form-auto'
import { ModalAutoform } from '../../ui/bootstrap/bootstrap'
import { Loading } from 'components/Loading'

import { Ingredient } from 'models/ingredient'
import { useGroups } from 'models/ingredientGroup'

export const IngredientForm = ({
  onClose,
  visible,
  doc,
  onSubmit,
  initialValues
}) => {
  const [ groups, loading ] = useGroups()

  if (loading)
    return <Loading />
  else {
    return (
      <ModalAutoform
        onSubmit={onSubmit}
        schema={Ingredient.schema}
        modalTitle={tr('models.ingredient.modal')}
        onClose={onClose}
        visible={visible}
        groups={groups}
        initialValues={initialValues}
      />
    )
  }
}

import { forwardRef } from 'react';
import { Autoform as RHFAutoform } from 'react-hook-form-auto'
import styles from './Autoform.sass'
import skin from './addCustomFields'

export let Autoform = (props, ref) => {
  return (
    <RHFAutoform
      styles={styles}
      skinOverride={skin}
      {...props}
      ref={ref}
    />
  )
}

Autoform = forwardRef(Autoform)

import { PropTypes } from 'prop-types'
import classnames from 'classnames'

import styles from './ListGroupItem.sass'

const getClasses = ({
  selected,
  className
}) =>
  classnames(styles.item, className, {
    active: selected
  })

const handleClick = (onClick, index) => {
  if (onClick)
    onClick(index)
}

export const ListGroupItem = ({
  className,
  onClick,
  index,
  selectable,
  selected,
  dontWrap,
  children
}) => {
  const clickHandler = handleClick.bind(null, onClick, index)
  const classes = getClasses({ selected, className })

  if (dontWrap) {
    return (
      <div
        className={classes}
        onClick={clickHandler}
      >
        {children}
      </div>
    )
  } else if (selectable) {
    return (
      <a
        className={classes}
        onClick={clickHandler}
      >
        {children}
      </a>
    )
  } else {
    return (
      <li className={classes}>
        {children}
      </li>
    )
  }
}

ListGroupItem.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  index: PropTypes.string,
  selected: PropTypes.bool,
  dontWrap: PropTypes.bool
}


import { useState } from 'react'
import PropTypes from 'prop-types'

import { LoginForm } from './LoginForm.jsx'
import { useLogin, useRegister, useUser } from 'pintor'

export const Login = ({
  onClose,
  onSubmit
}) => {
  const login = useLogin()
  const register = useRegister()
  const { currentUser, userLoading } = useUser()

  const [ canSubmit, setCanSubmit ] = useState(false)

  const enableButton = () => {
    setCanSubmit(true)
  }

  const disableButton = () => {
    setCanSubmit(false)
  }

  const handleSubmit = ({ doc, isRegister }) => {
    const {
      username,
      password,
      password_repeat,
      ...rest
    } = doc

    if (isRegister) {
      register({
        variables: {
          input: {
            username,
            password,
            ...rest
          }
        }
      })
    } else {
      login({
        variables: {
          username,
          password
        }
      })
    }

    if (onSubmit)
      onSubmit(doc)
  }

  // render()
  if (!userLoading && currentUser) {
    return "Hola de nuevo"
  } else {
    return (
      <LoginForm
        onSubmit={handleSubmit}
        onClose={onClose}
        initiallyRegister={false}
      />
    )
  }
}

import { PropTypes } from 'prop-types'

import { makeChildrenSelectable } from './SelectableComponent.js'
import { ListGroupItem } from './ListGroupItem.jsx'
import { arrayAddAdd } from './arrayAddAdd.jsx'

const handleChange = (onChange, index) => {
  if (onChange)
    onChange(index)
}

const wrappedChildren = () => {
  return makeChildrenSelectable(this)
}

export const ListGroup = ({
  selectable,
  onChange,
  children
}) => {
  if (selectable) {
    return (
      <div className="list-group">
        {children}
      </div>
    )
  } else {
    return (
      <ul className="list-group">
        {children}
      </ul>
    )
  }
}

ListGroup.propTypes = {
  selectable: PropTypes.bool,
  onChange: PropTypes.func
}

export const ListGroupWithAdd = arrayAddAdd(ListGroup, ListGroupItem)

import { Component } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu as BSDropdownMenu,
  DropdownItem
} from 'reactstrap'
// import { StyleSheet, css } from 'aphrodite'
import { Icon } from '../../ui/bootstrap/bootstrap'

import { ensureArray } from 'pintor'

import styles from './DropdownMenu.sass'

const renderItems = (doc, buttons) =>
  buttons.reduce((acc, button, idx) => {
    const buttonCfg = button.createFromDoc(doc)

    if (buttonCfg) {
      acc.push(
        <DropdownItem
          key={`${idx}`}
          onClick={buttonCfg.onClick}
        >
          <Icon
            className={styles[buttonCfg.type]}
            icon={buttonCfg.icon}
            text={buttonCfg.text}
          />
        </DropdownItem>
      )
    }

    return acc
  }, [])

export class DropdownMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }
  }

  toggle = () => {
    this.setState({
      visible: !this.state.visible
    })
  }

  render() {
    const {
      className,
      icon = 'cog',
      caret = true,
      doc,
      buttons,
      size,
      direction
    } = this.props

    const title = <Icon icon={icon} />

    return (
      <ButtonDropdown
        isOpen={this.state.visible}
        toggle={this.toggle}
        className={className}
        size={size}
        direction={direction}
      >
        <DropdownToggle caret={caret}>
          {title}
        </DropdownToggle>
        <BSDropdownMenu>
          {renderItems(doc, buttons)}
        </BSDropdownMenu>
      </ButtonDropdown>
    )
  }
}

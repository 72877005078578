import { useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Input } from '../../../ui/bootstrap/bootstrap'
import { InputSearch } from 'components/searcher/InputSearch.jsx'

import { Ingredient } from '../../../models/ingredient'
import { IngredientResults } from './IngredientResults'
import { IngredientForm } from '../IngredientForm'
import { IngredientTagFlex } from '../IngredientTagFlex.jsx'

export const IngredientSearch = props => {
  const inputSearch = useRef({})
  const createIngredient = Ingredient.useUpdate()

  const handleSelect = (doc, name) => {
    const { setValue, onSelect, arrayControl } = props

    setValue(`${name}.id`, doc.id)
    setValue(`${name}.type`, 'ingredient')
    setValue(`${name}.search`, doc.name)

    inputSearch.current.value = doc.name

    arrayControl.add()

    if (onSelect) {
      const { inputArrayIdx, inputArrayTotal } = props
      const isLast = inputArrayIdx >= inputArrayTotal
      onSelect(isLast)
    }
  }

  const handleCreate = async (doc, name) => {
    const result = await createIngredient(doc)

    handleSelect({
      id: result.id,
      name: result.name
    }, name)
  }

  return (
    <InputSearch
      {...props}
      createComponent={IngredientForm}
      resultsComponent={IngredientResults}
      ingredientRenderer={IngredientTagFlex}
      onSelect={handleSelect}
      onCreate={handleCreate}
      inputRef={e => inputSearch.current = e}
    />
  )
}

IngredientSearch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSelect: PropTypes.func,
  onCreate: PropTypes.func,
  meta: PropTypes.string
}

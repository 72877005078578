import { Spinner } from 'reactstrap'

import styles from './Loading.sass'

export function Loading() {
  return (
    <div key="loader" className={styles.spin}>
      <Spinner>
        {' '}
      </Spinner>
    </div>
  )
}

import React from 'react'
import { hydrate } from 'react-dom'
import { loadableReady } from '@loadable/component'
import { renderToString } from 'react-dom/server'
import { AppContainer } from 'react-hot-loader'
import { ApolloProvider, InMemoryCache } from '@apollo/client'

import { createClient } from './client'

import App from 'client/App'  // webpack alias
import { tokenGet, setCookieKey } from 'pintor'
import config from 'client/config'
const {
  graphqlExtUri,
  graphqlWsUri,
  routesInfo,
  addClientContext
} = config

let cache = new InMemoryCache()
if (typeof window != 'undefined')
  cache = cache.restore(window.__APOLLO_STATE__)

setCookieKey(config.cookieKey)
const token = tokenGet()

const client = createClient({
  ssr: false,
  uri: graphqlExtUri,
  wsUri: graphqlWsUri,
  cache,
  fetch,
  token,
  addClientContext
})

function render(AppComponent) {
	hydrate(
    <AppContainer>
      <ApolloProvider client={client}>
        <AppComponent />
      </ApolloProvider>
    </AppContainer>,
		document.getElementById('app')
	)
}

loadableReady(() => {
  render(App)
})

import PropTypes from 'prop-types'
import classnames from 'classnames'

export const Badge = ({
  onClick,
  className,
  type,
  pill,
  children,
  ...rest
}) => {
  const classes = classnames(
    'badge',
    type && `badge-${type}`,
    className
  )

  return (
    <span
      className={classes}
      onClick={onClick}
      {...rest}
    >
      {children}
    </span>
  )
}

Badge.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  pill: PropTypes.bool
}

import PropTypes from 'prop-types'
import { tr } from 'react-hook-form-auto'

import { IngredientTag } from '../IngredientTag'
import { FlexList } from 'components/FlexList'

const renderResults = ({
  col,
  onSelect,
  search,
  RenderComponent,
  selectedIdx
}) => {
  if (col.length > 0) {
    return col.map((doc, idx) => {
      const props = {
        key: doc.id,
        doc,
        onClick: onSelect.bind(null, doc),
        inline: true,
        selected: idx == selectedIdx,
        search
      }

      return (
        <RenderComponent key={doc.id} {...props} />
      )
    })
  }
}

const renderIngredientList = (params) => {
  const { col } = params

  if (col && col.length > 0)
    return renderResults(params)
  else
    return null
}

export const IngredientsList = ({
  loading,
  collection = [],
  search,
  searching,
  onSelect,
  onAddSelected,
  onResults,
  listWrapper,
  ingredientRenderer,
  selectedIdx
}) => {
  const Wrapper = listWrapper || FlexList
  const RenderComponent = ingredientRenderer || IngredientTag

  const ingElements = collection ? renderIngredientList({
    col: collection,
    onSelect,
    RenderComponent,
    selectedIdx,
    search
  }) : []

  if (onResults)
    onResults(collection)

  return (
    <Wrapper>
      {ingElements}
      <RenderComponent
        text={tr('add')}
        onClick={onAddSelected}
        isAdd
        inline
      />
    </Wrapper>
  )
}

IngredientsList.propTypes = {
  loading: PropTypes.bool,
  collection: PropTypes.array,
  onSelect: PropTypes.func,
  onAddSelected: PropTypes.func
}

import { useState } from 'react';
import { Checkbox } from '../components/Checkbox'
import { tr } from 'react-hook-form-auto'
import { A } from 'hookrouter'

export function MakeItPublicField({
  schemaTypeName,
  fieldSchema,
  name,
  defaultValue,
  styles = {},
  field,
  register,
  autoformProps
}) {
  const [ checked, setChecked ] = useState(defaultValue)

  const { initialValues } = autoformProps
  const id = initialValues && initialValues.id

  function handleChange(e) {
    setChecked(e.target.checked)
  }

  const { path } = fieldSchema

  return (
    <div>
      <Checkbox
        schemaTypeName={schemaTypeName}
        name={name}
        defaultValue={defaultValue}
        styles={styles}
        field={field}
        onChange={handleChange}
        {...register(name)}
      />
      {
        checked &&
          (id &&
            <A href={`/${path}/${id}`}>{tr('link')}</A>
          ||
            <div>{tr('linkPromise')}</div>
          )
      }
    </div>
  )
}

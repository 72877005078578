import { cloneElement } from 'react';
import classnames from 'classnames'

import styles from './FlexList.sass'

const renderContentChildren = ({ children }) => {
  return children.map(child => cloneElement(child, {
    className: classnames(
      styles.contentItem,
      child.props.className
    )
  }))
}

export const FlexContent = ({
  className,
  children
}) =>
  <div className={classnames(styles.contents, className)}>
    {renderContentChildren({ children })}
  </div>

export const FlexItem = ({
  className,
  children,
  ...rest
}) =>
  <span
    className={classnames(styles.element, className)}
    {...rest}
  >
    {children}
  </span>

export const FlexList = ({
  className,
  children
}) =>
  <div className={classnames(styles.wrapper, className)}>
    {children}
  </div>

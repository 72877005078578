import { PropTypes } from 'prop-types'
import classnames from 'classnames'

const entryClass = ({ selected }) => {
  return classnames('nav-item', { active: selected })
}

const renderLink = ({
  dontWrap,
  children
}) => {
  if (dontWrap)
    return children
  else
    return <a className="nav-link" href="/">{children}</a>
}

export const NavItem = ({
  onClick,
  index,
  selected,
  dontWrap,
  children
}) =>
  <li
    role="presentation"
    key={index}
    onClick={onClick}
    className={entryClass({ selected })}
  >
    {renderLink({ dontWrap, children })}
  </li>

NavItem.propTypes = {
  onClick: PropTypes.func,
  index: PropTypes.string,
  selected: PropTypes.bool,
  dontWrap: PropTypes.bool
}

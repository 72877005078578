import { PropTypes } from 'prop-types'
import classnames from 'classnames'
import Icon from './Icon'

const drawIcon = ({
  iconClass
}) => {
  if (iconClass)
    return <Icon icon={iconClass} />
  else
    return null
}

export const Label = ({
  labelClass,
  iconClass,
  children
}) => {
  const classes = classnames([
    'label',
    'label-' + labelClass
  ])

  return (
    <span className={classes} aria-hidden="true">
      {drawIcon({ iconClass })}
      {children}
    </span>
  )
}

Label.propTypes = {
  iconClass: PropTypes.string,
  children: PropTypes.any
}

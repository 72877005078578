import { PropTypes } from 'prop-types'

import { useQuery } from '@apollo/client'

import { IngredientsList } from './IngredientsList'
import { Ingredient } from 'models/ingredient'

export const IngredientResults = ({
  search,
  ...rest
}) => {
  const { data, loading } = useQuery(Ingredient.search, {
    variables: { search, seeEveryones: true }
  })
  const collection = data && data.collection

  if (!loading && collection) {
    return (
      <IngredientsList
        search={search}
        collection={collection}
        {...rest}
      />
    )
  } else
    return <p>...</p>
}
